@media (max-width: $breakpoint-sm) {
  .dropdown-filter {
    & .dropdown {
      position: initial;
    }
    & .dropdown-menu {
      margin: 16px 0;
      width: calc(100% - 30px);
    }

    &__input {
      @include input-sm;
      width: 100%;
      border: 1px solid black;
    }
  }
}

.dropdown-filter {
  &__title {
    font-weight: 700;
    font-size: 20px;
  }

  &__label {
    font-size: 18px;
    font-weight: 600;
  }
}
