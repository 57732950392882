$primary-btn: #375c79;
$dark-primary-btn: #00334d;
$bg-gray: #f8f8f8;
$light-red: #dc3545;
$dark-red: #a3001e;
$light-gray: #98a2ac;
$dark-gray: #6a737d;
$ocean-blue: #4582ec;
$light-blue: #00b2ff;
$black: #343a40;

$breakpoint-sm: 576px;
$header-height-desktop: 96.8px;
$footer-height-desktop: 104px;
$header-height-mobile: 64px;
$footer-height-mobile: 128px;
