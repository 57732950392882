.form {
  label {
    font-weight: 600;
    font-size: 0.95rem;
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='password'],
  input[type='date'],
  textarea {
    border: 1px solid $black;
    border-radius: 0;
  }

  input[type='radio'] + label {
    font-weight: 400;
  }
}

@media (max-width: $breakpoint-sm) {
  .form {
    label {
      font-size: 18px;
      line-height: 25px;
    }
    & input {
      font-size: 20px;
    }
  }
}
