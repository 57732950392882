.navbar-container {
  .navbar-items {
    display: flex;
    justify-content: flex-end;
  }

  .burger-toggler {
    display: none;
  }

  .navbar {
    padding: 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &__logout-btn {
      color: $black;
      background: transparent;
      font-weight: 600;
      border: 0;
      padding: 10px 0 10px 25px;
      font-size: 0.95rem;
      text-align: right;

      &:hover {
        text-decoration: underline;
      }
    }

    .navbar-nav {
      a {
        color: $black;
        font-weight: 600;
        font-size: 0.95rem;
        padding: 10px 25px;

        + svg {
          display: none;
        }

        @media (min-width: 992px) {
          &.active + svg {
            display: block;
          }
        }
      }
    }
  }

  .navbar-logo-button {
    min-height: 50px;
    box-shadow: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .navbar-container {
    .navbar-items {
      display: none;
    }

    .navbar {
      display: flex;
      height: 64px;
      justify-content: space-between;
    }

    .navbar-toggler {
      display: block;
    }

    .burger-toggler {
      border: none;
      background-color: inherit;
      display: block;
      padding: 0;

      & span {
        display: block;
        width: 24px;
        height: 24px;
        background: url('~assets/images/burger.svg');
        background-position: center center;
        background-size: 100% 100%;
      }
    }
  }
}
