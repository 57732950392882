.datepicker {
  width: 150px;
  position: relative;
  padding: 4px;
  border: 1px solid rgba(55, 92, 121, 0.75);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  background: url('~assets/images/calendar_icon.svg') no-repeat;
  background-size: 20px;
  background-position: right 4px top 50%;
  background-color: #fff;
}

@media (max-width: $breakpoint-sm) {
  .react-datepicker-wrapper {
    width: 100%;
  }
  .datepicker {
    padding: 10px 12px;
    width: 100%;
    font-size: 20px;
    background: url('~assets/images/calendar_icon.svg') no-repeat;
    background-size: 35px;
    background-position: right 10px top 50%;
    background-color: #fff;
  }
}
