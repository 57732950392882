@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;900&family=Raleway:wght@700,900&display=swap');

h1,
h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.1rem;
  font-weight: 900;
}

p,
li {
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  font-size: 1rem;
  line-height: 1.5;
}

a {
  color: $ocean-blue;
}

body {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

.text-light-blue {
  color: $light-blue;
}

.text-black {
  color: black;
}

.text-extra-bold {
  font-weight: 900;
}

.text-red {
  color: #dc3545;
}

.text-blue {
  color: $primary-btn;
}

.opaque {
  opacity: 1 !important;
}

.text-link {
  color: $ocean-blue;

  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }
}

.validation-alert {
  top: 40px;
  font-size: 11px;
  color: #dc3545;
}

.border-danger .typeahead input {
  border: 1px solid #dc3545;
}

@media (max-width: $breakpoint-sm) {
  body {
    min-height: 100vh;
  }

  .validation-alert {
    font-size: 14px;
    margin-top: 3px;
  }
}

.subtitle {
  font-size: 20px !important;
}

.tooltip-rebalance {
  background-color: #375c79 !important;
  border: 1px solid #375c79;
  opacity: 1 !important;
  &::after {
    opacity: 1 !important;
    background-color: #375c79 !important;
    border: 1px solid #375c79;
  }
}
