.form-box {
  background-color: white;
  padding: 2rem;
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  &__title {
    font-family: Raleway, sans-serif;
    font-weight: 900;
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 1.1rem;
  }

  &__src {
    font-weight: 700;
    font-size: 1rem;
  }
}

@media (max-width: $breakpoint-sm) {
  .form-box {
    padding: 16px 20px;
    margin: 0 0 16px 0;

    &__title {
      margin: 0 0 12px 0;
    }
  }
}
