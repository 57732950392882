@media (max-width: $breakpoint-sm) {
  .statements {
    h1 {
      font-size: 24px;
    }

    h2,
    h4 {
      font-size: 20px;
    }

    &__select {
      @include select-sm;
    }
  }
}
