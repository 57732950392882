.ach-contribution-allocation {
  .contribution-info {
    background-color: #e5e5e5;
    padding: 10px;
    margin: 40px 0;

    table {
      width: 100%;
      font-weight: 800;
    }
  }

  .allocation-submitted {
    .allocation-info {
      background-color: #e5e5e5;
    }
  }
}

.allocation-info {
  table {
    border-collapse: separate;

    &.general-info {
      border-spacing: 0 10px;
    }

    &.pool-info {
      border-spacing: 0 5px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .finish-investment-wrapper {
    padding: 0;
  }

  .ach-contribution-cards-wrapper {
    row-gap: 45px;
  }

  .allocation-submitted {
    h1 {
      font-size: 24px;
    }

    &__wrapper {
      padding: 0;
    }
  }

  .ach-contribution-allocation {
    h2 {
      font-size: 20px;
    }

    .h4,
    h4 {
      font-size: 20px;
    }

    &__checkbox {
      display: flex;
      align-items: center;

      & .form-check-input {
        margin-top: 0;
        width: 20px;
        height: 20px;
      }

      .form-check-label {
        font-size: 16px;
        padding-left: 10px;
        vertical-align: bottom;
      }
    }

    .text-danger,
    .text-success {
      font-size: 24px;
    }

    .contribution-info {
      padding: 16px;
      margin: 24px 0;
    }

    &__navigation-button {
      display: block;
      width: 100%;
    }

    & .funds-table {
      &__investment-pool {
        min-width: 95px;
      }

      &__current-balance {
        min-width: 80px;
      }

      &__current-units {
        min-width: 75px;
      }

      &__current-allocation {
        min-width: 80px;
      }

      &__investment-allocation {
        min-width: 146px;
      }

      & .number-format {
        @include input-sm;
      }
    }
  }
}

.contribution-tooltip {
  .contribution-tooltip-icon {
    color: $light-gray;
  }

  .tooltip-text {
    font-weight: 400;
    font-size: 16px;
    max-width: 400px;
  }

  .custom-tooltip {
    background-color: #fff !important;
    opacity: 1 !important;
    border: 1px solid #375c79 !important;
  }

  .highlight-tooltip-icon {
    color: #375c79 !important;
  }
}
