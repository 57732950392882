$invalid-color: #dc3545;

.grant-request {
  display: flex;
  flex-direction: column-reverse;

  &__navigation {
    padding: 60px 0 16px 0;
  }

  &__navigation-buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__grantees-list {
    list-style: none;
  }

  &__chosen-organizations {
    width: 100%;
  }

  & .chosen-organizations__header {
    border-bottom: 1px solid #e4e4e4;
    font-weight: normal;
  }

  &__chosen-organizations-name {
    border-bottom: 1px solid #e4e4e4;
  }

  & .organization-name {
    width: 30%;
  }

  & .organization-ein {
    width: 20%;
  }

  & .organization-address {
    width: 50%;
  }
}

.modal-body {
  .modal-button {
    width: 50%;

    button {
      width: 200px;
    }
  }
}

@media (max-width: 575px) {
  .modal-body {
    .modal-button {
      button {
        width: 130px;
      }
    }
  }
}

.grant-instructions {
  background-color: #f8f8f8;

  p {
    font-size: 1.25rem;
    font-family: 'Open Sans', sans-serif;
  }
}

.grant-form-wrapper {
  background-color: white;
  min-height: 46vh;
  .grant-form {
    margin: 0 auto;
    max-width: 43.0625rem;
    .prev-btn {
      height: 50px;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      min-width: 146px;
      padding: 0 20px;
      box-shadow: none;
      border: none;
    }
    .next-btn {
      color: #fff;
      background-color: #4582ec;
      height: 50px;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      min-width: 146px;
      padding: 0 20px;
      box-shadow: none;
      border: none;
      &:hover {
        background-color: #226be8;
      }
    }
  }
}

.review-table {
  font-size: 1.3rem;
  max-width: 500px;
  margin: 0 auto 20px;

  .total {
    border-top: 1px solid black;
  }
  .amount {
    font-weight: bold;
  }
}

.new-grantee-modal {
  .button-container {
    width: 50%;

    button {
      height: 60px;
      width: 200px;

      @media (max-width: 575px) {
        width: 90%;
      }
    }
  }
}

.new-grantee-alert .modal-content {
  background-color: #eafaf9;
  max-width: 400px;
  text-align: center;
  margin: auto;
}

.invalid-allocation {
  font-size: 18px;
  color: #dc3545;
}

.reccuring-grant-info {
  cursor: pointer;

  ul {
    max-width: 300px;
    padding-left: 5px;

    li {
      font-size: 13px;
    }
  }
}

.grant-request__popup {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

.grant-request__complete {
  h1 {
    font-size: 27px;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }

  .grant-request__navigation-buttons {
    button {
      width: 277px;
      height: 54px;
    }

    .button--dark-blue {
      margin-left: auto;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .grant-request {
    overflow: hidden;

    & .input-group {
      flex-wrap: nowrap;
    }

    & .form-label {
      font-size: 18px;
    }

    & h4 {
      font-size: 20px;
    }

    &__label {
      font-size: 16px;
    }

    &__complete-buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      white-space: nowrap;
      font-size: 14px;

      & a {
        width: 100%;
        text-align: center;
        vertical-align: center;
      }
    }

    & .allocate-pool {
      margin-bottom: 20px;
      & td {
        font-size: 16px;
        padding: 12px 0;
      }
    }

    & .form-control {
      font-size: 20px;
      padding: 0 10px;

      &::placeholder {
        font-size: 14px;
      }
    }

    &__navigation {
      padding: 30px 0 16px 0;
    }

    &__available-funds {
      & h2 {
        font-size: 20px;

        & u {
          text-decoration: none;
          border-bottom: 2px solid #000;
        }
      }
    }

    &__sum {
      font-size: 20px;
      position: relative;
      bottom: 3px;
    }

    &__navigation-buttons {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;

      & button {
        width: 100%;
      }
    }

    &__grantees-list {
      padding-left: 0;

      & li {
        padding-top: 8px;
      }
    }

    &__chosen-organizations {
      width: 100%;
    }

    &__chosen-organizations-name {
      font-size: 20px;
      border-bottom: none;
    }

    & .grant-table-container {
      overflow-x: scroll;
    }
  }

  .grant-instructions {
    & h2 {
      font-size: 24px;
    }

    & p {
      font-size: 16px;
    }

    & h4 {
      font-size: 12px;
    }
  }

  .add-grant-info {
    & .grant-table-container {
      thead th {
        padding: 12px 6px;
        font-weight: 500;
        font-size: 12px;
      }

      tbody td {
        padding: 12px 6px;
        font-size: 12px;
        vertical-align: middle;
      }

      & .table-name {
        min-width: 95px;
      }
      & .table-amount {
        min-width: 65px;
      }
      & .table-designation {
        min-width: 75px;
      }
      & .table-acknowledgement {
        min-width: 115px;
      }
      & .table-timing {
        min-width: 75px;
      }
      & .table-wire-grant {
        min-width: 85px;
      }
      & .table-comments {
        min-width: 85px;
      }

      & .table-comment_desktop {
        display: none;
      }
      & .table-comment_mobile {
        text-align: left;
      }

      & .table-controls {
        display: flex;
        column-gap: 16px;

        & button {
          padding: 8px 12px;
          border-radius: 4px;
        }
      }
    }

    & .button--edit,
    .button--bin {
      display: none;
    }
  }

  .edit-grant-mobile-modal {
    .modal-body {
      padding: 16px 0;
    }
  }

  .edit-grant-mobile {
    & .form-label {
      font-size: 16px;
      font-weight: bold;
    }

    &__checkbox {
      width: 20px;
      height: 20px;
    }

    & textarea.form-control {
      height: 190px;
      resize: none;
    }

    .tooltip {
      left: 15px !important;
      right: 15px !important;
      width: calc(100% - 30px) !important;
      font-size: 14px;
    }

    .radio-input-toggle {
      &__input {
        &.form-control {
          @include input-sm;
        }
      }
    }
  }
}

.edit-grant-mobile {
  .amount {
    justify-content: normal !important;

    .amount-input {
      left: 95px;
      min-width: 200px;
    }
  }
}

@media (max-width: 575px) {
  .edit-grant-mobile {
    .amount {
      justify-content: normal !important;

      .amount-input {
        left: 0;
        max-width: 150px;
      }
    }
  }
}

.edit-grant-mobile {
  @media (max-width: 575px) {
    .edit-recurring-grant-table-modal__input,
    input[type='text'].radio-input-toggle__input {
      border-color: #375c79;
      border-radius: 3px;
      max-width: 400px;
    }
  }
}

.timing-popup,
.acknowledgement-popup,
.designation-popup,
.edit-grant-mobile {
  .form-check-label {
    color: black;
  }

  .grant-instructions {
    .container {
      padding-top: 20px;
      h2 {
        font-size: 28px;
      }
    }
  }

  .description {
    font-size: 16px;
  }

  legend {
    font-size: 20px;
    font-weight: bold;
  }

  label {
    font-size: 16px;
  }

  input[type='date'] {
    border-color: #375c79 !important;
    border-radius: 3px;
    margin-left: 10px;
    width: 200px !important;
    max-width: 200px;

    &.invalid {
      border-color: $invalid-color;
    }
  }

  @media (max-width: 575px) {
    input[type='date'] {
      width: 175px !important;
      max-width: 175px;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url('~assets/images/custom-calendar.svg');
  }

  textarea,
  input[type='text'] {
    border-color: #375c79;
    border-radius: 3px;
    max-width: 400px;

    &.invalid {
      border-color: $invalid-color;
    }
  }

  @media (max-width: 575px) {
    .edit-recurring-grant-table-modal__input,
    input[type='text'].radio-input-toggle__input {
      border-color: #375c79;
      border-radius: 3px;
      max-width: 400px;
    }
  }

  .form-check .form-check-label {
    font-weight: 400;
  }

  .ending-on {
    margin-right: 22px;
  }

  .tooltip {
    background-color: #375c79;
    color: white;
    border: 1px solid #375c79 !important;
    opacity: 1 !important;
    max-width: 390px;

    &::after {
      background-color: #375c79 !important;
      border: 1px solid #375c79 !important;

      @media (max-width: 575px) {
        left: 200px !important;
      }
    }

    .subtitle,
    .recurrence-type {
      font-weight: 600 !important;
    }

    ul {
      padding-left: 10px;

      li {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
      }
    }
  }

  table {
    @media (min-width: 576px) {
      margin-left: 70px;

      .form-group {
        margin-left: 70px;
      }
    }
  }

  .validation-message {
    color: $invalid-color;

    &__start-date {
      margin-left: 130px;
      color: $invalid-color;
    }

    &__frequency {
      color: $invalid-color;
    }
  }

  .react-datepicker__input-container {
    left: 10px;
    .datepicker {
      width: 200px;
      height: 40px;
    }

    .invalid {
      border: 1px solid $invalid-color;
    }
  }
}

.edit-grant-mobile .react-datepicker__input-container {
  left: 0px;
}

.timing-popup,
.timing-component {
  .validation-message {
    padding-left: 10px;
  }
}

.edit-grant-mobile .timing {
  .validation-message {
    padding-left: 10px;
  }
}

@media (max-width: 575px) {
  .edit-grant-mobile {
    .start-date.form-group.col-md-5,
    .d-flex.frequency.form-group {
      margin-bottom: 0px;
    }

    .validation-message__start-date {
      margin-left: 5px;
    }
  }
}
