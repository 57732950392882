.typeahead {
  input {
    border: 1px solid black;
    border-radius: 0;
    padding: 30px;
  }

  & .rbt-highlight-text {
    padding: 0;
  }

  .rbt-menu-custom-option {
    background-color: $primary-btn;
    color: white;
    padding: 20px;
  }

  & .dropdown-item {
    padding: 4px 24px;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__options-name {
    max-width: 60%;
    white-space: normal;
    color: black;
  }
}

@media (max-width: $breakpoint-sm) {
  .typeahead {
    & .dropdown-item {
      padding: 0 10px 10px 10px;
    }
  }
}
