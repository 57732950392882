.button-goTo {
  position: sticky;
  left: 90%;
  width: 48px;
  height: 48px;
  border-radius: 0;
  background: white;
  color: #c9c9c9;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  border: 3px solid #f8f8f8;

  &__bottom {
    bottom: 115px;
  }

  &__top {
    bottom: 170px;
  }

  &__show {
    opacity: 1;
    pointer-events: all;
  }

  &__hidden {
    opacity: 0;
    pointer-events: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .button-goTo {
    width: 36px;
    height: 36px;
    left: 85%;
    border-radius: 50px;
    font-size: 24px;
    background-color: transparent;

    &__bottom {
      bottom: 30px;
    }

    &__top {
      bottom: 75px;
    }
  }
}
