@media (max-width: $breakpoint-sm) {
  .common-forms {
    h2 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
    }
  }
}
