.btn-primary {
  background-color: $primary-btn;
  border: none;
}

.bi-cloud-arrow-down-fill::before {
  content: url('../images/download_icon.svg') !important;
}

@media (max-width: $breakpoint-sm) {
  .bi-cloud-arrow-down-fill::before {
    content: url('../images/download_icon_mobile.svg') !important;
  }
}

.bi-cloud-arrow-up-fill {
  color: #375c79 !important;
}
