.your-funds-wrapper {
  height: 100%;
  overflow: hidden;
  .your-funds {
    justify-content: space-between;
    .card {
      height: 100%;
      margin-top: 20px;
      width: 49%;
      padding: 15px;
      min-height: 40vh;
      @media (max-width: 990px) {
        width: 100%;
        height: 100%;
      }

      .card-footer {
        font-size: 13.4px;
        @media (min-width: 990px) {
          position: absolute;
          bottom: 0;
          width: 95%;
        }
      }
    }

    .funds-table {
      font-size: 13.4px;

      th {
        border-top: none;
        vertical-align: top;
        font-size: 11px;
      }

      tbody {
        td:first-child {
          font-weight: 900;
        }
        tr {
          border-bottom: 1px solid #dee2e6;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .your-funds-wrapper {
    & .your-funds-greeting {
      h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
