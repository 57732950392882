@media (max-width: $breakpoint-sm) {
  .pool-choice {
    & h1 {
      font-size: 24px;
    }

    & h3 {
      font-size: 24px;
    }

    & .finish-change-wrapper {
      padding: 0;
    }

    & .h4 {
      font-size: 20px;
    }

    & .form-control {
      padding: 0 8px;
      font-size: 16px;
    }

    & .form-check-input {
      width: 20px;
      height: 20px;
    }

    &__checkbox-exchange {
      display: flex;
      align-items: center;

      & .form-check-input {
        margin-top: 0;
      }
    }

    &__download-pdf {
      width: 56px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px !important;
      margin-top: 20px !important;
    }

    .text-danger,
    .text-success {
      font-size: 24px;
    }

    & .tooltip {
      left: 15px !important;
      right: 15px !important;
      width: calc(100% - 30px) !important;
      font-size: 14px;
    }

    & .form-check-label {
      font-size: 16px;
      padding-left: 10px;
      vertical-align: bottom;
    }

    & .funds-table {
      &__investment-pool {
        min-width: 95px;
      }

      &__current-balance {
        min-width: 80px;
      }

      &__current-units {
        min-width: 75px;
      }

      &__current-allocation {
        min-width: 80px;
      }

      &__investment-allocation {
        min-width: 146px;
      }

      & .number-format {
        @include input-sm;
      }
    }
  }
}

.pool-choice__download-pdf {
  padding: 0;
  background-color: white;
  width: 28px;
  height: 28px;
}
