.transactions {
  & .transactions-title {
    font-size: 20px;
  }

  & .filter-wrapper {
    background-color: #f8f8f8;
    padding-bottom: 14px !important;
  }

  & .no-results-icon {
    font-size: 35px;
  }

  & .transactions-lable {
    color: #6c757d;
  }

  & .transactions-select {
    color: black;
    height: 34px;
    width: 150px;
    position: relative;
    padding: 4px;
    border: 1px solid rgba(55, 92, 121, 0.75);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }

  & .transaction-dropdown {
    &:disabled {
      & .download-icon {
        color: #9ca4ab;
      }
    }
  }

  & .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(52, 58, 64, 0.15);
    border-radius: 0.25rem;

    &__inactive {
      display: none;
    }

    &__active {
      display: block;
      inset: 0px 0px auto auto;
      margin: 0px;
      transform: translate3d(0px, 40px, 0px);
    }
  }

  & .download-icon {
    color: $primary-btn;
    font-size: 25px;
    font-weight: bold;

    &:hover {
      color: $dark-primary-btn;
      transition: all 0.7s;
    }
  }

  & .transactions-table {
    .w-30 {
      width: 30%;
    }
    .w-20 {
      width: 20%;
    }
    .w-15 {
      width: 15%;
    }
    thead th {
      border-top: 0;
      color: #acacac;
      vertical-align: top;
    }

    .sortable {
      cursor: pointer;
    }

    tbody td {
      border-bottom: 2px solid #dee2e6;
      padding: 15px 12px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .transactions {
    h2 {
      font-size: 24px;
    }

    & .header-navigate {
      background-color: $bg-gray;
      padding: 20px 0;
    }

    & .transactions-filter {
      padding: 0;
    }

    & .transactions-select {
      height: 52px;
      padding: 10px 12px;
      width: 100%;
      font-size: 20px;
    }

    & .transactions-header-pool {
      width: 100%;
    }

    & .transactions-table {
      overflow-x: scroll;
    }

    & .transactions-lable {
      font-weight: bold;
      color: black;
    }

    & .transactions-showmore-button {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      gap: 10px;
      height: 40px;
      background: #ffffff;
      border: 1px solid rgba(172, 172, 172, 0.6);
      border-radius: 20px;

      &:active {
        background: $bg-gray;
      }
    }
  }
} ;
