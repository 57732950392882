.password-form {
  input[type='password'] {
    padding-right: 50px;
  }

  .password-input {
    background-image: none !important;
  }

  .visibility {
    margin-left: -30px;
    z-index: 999;
  }

  .tooltip-password {
    color: black;
    width: 250px;
    background-color: white;
    border: 1px solid #00334d;
    padding-left: 15px;
    padding-right: 15px;
    opacity: 1 !important;
    &::after {
      background-color: white !important;
      border: 1px solid #00334d !important;
    }

    &:hover {
      visibility: visible !important;
    }

    strong {
      color: #00334d;
      font-size: 14px;
    }

    ul {
      padding-left: 15px;
      margin-bottom: 0;

      li {
        font-size: 12px;
        font-family: inherit;

        &::marker {
          color: #00334d;
        }

        &.invalid {
          color: #dc3545;
          &::marker {
            color: #dc3545;
          }
        }

        &.valid {
          color: #43aa00;
          &::marker {
            color: #43aa00;
          }
        }
      }
    }
  }
}

.reset-password-form {
  @media (min-width: 576px) {
    max-width: 300px;
    margin: auto;
  }
}

.expired-instruction {
  @media (min-width: 576px) {
    text-align: center;
  }

  @media (max-width: 575px) {
    text-align: left !important;
    .first-sentence {
      margin-bottom: 20px;
    }
  }
}

.reset-password-instruction {
  .instruction-container {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .text-muted {
      font-size: 16px;
      @media (min-width: 576px) {
        text-align: center;
      }

      @media (max-width: 575px) {
        text-align: left !important;
        .first-sentence {
          margin-bottom: 20px;
        }
      }
    }
  }
}
