@media (max-width: $breakpoint-sm) {
  .grantee-history-modal {
    &__input {
      @include input-sm;
    }

    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 20px;
    }

    &_label {
      font-size: 18px;
    }

    &__multiselect {
      & .dropdown-container {
        height: 52px;
        border-radius: 0 !important;
        padding: 7px 0;
        font-size: 20px;
      }
    }

    .modal-body {
      padding: 16px 0;
    }

    &__reset-button,
    &__submit-button {
      width: 100%;
    }

    & .grant-instructions {
      font-size: 12px;
    }
  }
}

.grantee-history-modal {
  .history-dropdown {
    background-color: #375c79;
    border-radius: 10%;
  }

  .show > .btn-primary.dropdown-toggle {
    background-color: #375c79;
  }
}
