.main-wrapper {
  height: 100%;
  background-color: white;
  min-height: calc(100vh - #{$header-height-desktop} - #{$footer-height-desktop});

  .sub-nav {
    padding: 40px 0;
    background-color: $bg-gray;

    .back-btn {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .main-wrapper {
    min-height: calc(100vh - #{$header-height-mobile} - #{$footer-height-mobile});

    .sub-nav {
      padding: 20px 0;
    }

    & .form-control {
      @include input-sm;
    }
  }
}
