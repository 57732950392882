@media (max-width: $breakpoint-sm) {
  .review-recurring-grants {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h4 {
      font-size: 20px;
    }

    &__table-name {
      min-width: 95px;
    }
    &__table-frequency {
      min-width: 63px;
    }
    &__table-value {
      min-width: 80px;
    }
    &__table-start {
      min-width: 70px;
    }
    &__table-end {
      min-width: 65px;
    }
    &__table-created {
      min-width: 65px;
    }
    &__table-designation {
      min-width: 86px;
    }
    &__table-acknowledgement {
      min-width: 112px;
    }
    &__extended-edit {
      display: none;
    }
  }
}
