.downshift-wrapper {
  position: relative;

  & .select {
    color: black;
    height: 34px;
    width: 185px;
    position: relative;
    padding: 4px 20px 4px 4px;
    border: 1px solid rgba(55, 92, 121, 0.75);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    background: url('~assets/images/arrow_down.svg') no-repeat;
    background-size: 12px 8px;
    background-position: right 4px top 50%;
    background-color: #fff;
    text-align: left;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &__unselected {
      color: #c9c9c9;
    }
  }

  & .menu {
    position: absolute;
    width: 185px;
    z-index: 1;
    border: 1px solid rgba(55, 92, 121, 0.75);
    border-top: none;
    background-color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }

  & .search-input {
    border: 1px solid #c9c9c9;
    width: 100%;
    height: 30px;
    padding-right: 21px;
    &::placeholder {
      padding-left: 21px;
      color: #c9c9c9;
    }

    &:focus {
      border: 1px solid #c9c9c9;
    }

    &__empty {
      background: url('~assets/images/search_icon.svg') no-repeat;
      background-size: 16px;
      background-position: left 5px top 50%;
    }
  }

  & .close-button {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 16px;
    height: 16px;
    background: url('~assets/images/x-icon.svg') no-repeat;
    background-size: 7px;
    background-position: left 50% top 50%;
  }

  & .downshift-control {
    border: 1px solid #c9c9c9;
    font-size: 14px;
    height: 30px;
    width: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #fff;
      background-color: $primary-btn;
    }
  }

  & .no-matches {
    border: 1px solid #dc3545;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .items-wrapper {
    overflow-y: auto;
    max-height: 250px;

    & .item {
      padding: 6.5px 10px;

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }

    & .item-selected {
      background-color: #e9ecef;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .downshift-wrapper {
    width: 100%;
    & .select {
      width: 100%;
      height: 52px;
      font-size: 20px;
      padding: 4px 20px 4px 12px;
    }

    & .menu {
      width: 100%;
    }

    & .search-input {
      padding-right: 21px;
      padding-left: 12px;
      height: 52px;
      font-size: 20px;
      &::placeholder {
        padding-left: 25px;
        color: #c9c9c9;
      }

      &:focus {
        border: 1px solid #c9c9c9;
      }

      &__empty {
        background-size: 20px;
        background-position: left 12px top 50%;
      }
    }

    & .downshift-control {
      font-size: 16px;
      height: 40px;
      width: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
