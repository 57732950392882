.daf-fund-name-list {
  list-style: none;
}

.daf-prefixes-container {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
}

.typeahead-state {
  & .dropdown-menu {
    min-width: 3rem;
  }

  & .dropdown-item {
    padding: 0.375rem 0.75rem;
  }

  & .rbt-highlight-text {
    padding: 0;
  }
}

.typeahead-city {
  & .dropdown-item {
    padding: 0.375rem 0.75rem;
  }

  & .rbt-highlight-text {
    padding: 0;
  }
}

.daf-container {
  & .form-control {
    border: 1px solid $black;
    border-radius: 0;
    color: black;
  }

  & .daf-files-list {
    list-style: none;
  }

  & .daf-textarea {
    resize: none;
  }

  & .daf-checkbox {
    & .form-check-label {
      margin-left: 20px;
    }
  }

  & .daf-plus-icon {
    font-size: 24px;
  }

  & .sunsetting-daf-years {
    &__radio {
      margin-top: 12px;
    }
    &__text {
      margin-top: 7px;
    }
  }

  & .daf-error-popup {
    width: 20%;
  }

  & .daf-dropzone-upload {
    border: 1px dashed black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
  }

  & .validation-files {
    color: #dc3545;
  }

  .input-error {
    border: 1px solid #dc3545;
    box-shadow: 3px #dc3545;
  }

  .daf-datepicker {
    min-width: 160px;
  }
}
