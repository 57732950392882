@media (max-width: $breakpoint-sm) {
  .edit-recurring-grant-table-modal {
    &__input {
      @include input-sm;
    }

    &__select {
      @include select-sm;

      & div {
        margin-left: 0;
      }
    }

    &__label {
      font-size: 18px;
    }
  }
}
