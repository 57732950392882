.login-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .login-banner {
    height: 150px;
    max-width: 800px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('~assets/images/login_banner.png');
    background-size: contain;
  }

  .login-header {
    padding-top: 20px;
    font-weight: 700;
  }

  .bottom-banner {
    width: 100%;
    max-width: 800px;
    height: 110px;
    background-color: #cfab7a;
  }
}

@media (max-width: $breakpoint-sm) {
  .login-wrapper {
    .login-banner {
      height: 64px;
    }
    .login-header {
      padding: 24px 0;
    }
    .bottom-banner {
      display: none;
    }

    input {
      @include input-sm;
    }

    & .form-row > .col,
    .form-row > [class*='col-'] {
      padding: 0 12px;
    }

    .alert-info {
      padding: 16px;
    }
  }
}
