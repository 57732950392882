.fund-card {
  width: 100%;
  margin: 15px;

  @media (min-width: 576px) {
    width: 500px;
  }

  &__title {
    font-weight: 700;
    font-size: 1.1rem;
    font-family: Raleway, sans-serif;
  }

  &__id {
    font-size: 16px;
    text-align: left;
  }

  &__value,
  &__date {
    margin: 0;
    padding: 0;
    text-align: right;
  }

  &__value {
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    font-size: 1.1rem;
  }

  &__date {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    color: rgb(172, 172, 172);
  }
}

@media (max-width: $breakpoint-sm) {
  .fund-card {
    margin: 0;

    &__title {
      margin-bottom: 8px;
      font-size: 20px;
    }

    &__id {
      font-size: 16px;
      display: inline-block;
      float: right;
      padding-top: 3px;
    }

    &__date {
      margin: 0;
      padding: 0;
      text-align: left;
      font-size: 14px;
    }

    &__value {
      margin: 0;
      padding: 0;
      text-align: left;
      font-size: 22px;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}
