.daf-links {
  overflow-y: hidden;
}

.daf-links-accordion {
  &__false {
    transform: rotate(180deg);
    transition: transform 0.5s;
  }

  &__true {
    transform: rotate(0);
    transition: transform 0.5s;
  }
}

.daf-links-content {
  transition: transform 1s;

  &__link {
    margin-left: 20px;
    font-family: sans-serif;
  }

  &__false {
    margin-top: -100%;
    transition: margin-top 0.5s, opacity 0.5s;
    opacity: 0;
  }

  &__true {
    margin-top: 0;
    transition: margin-top 0.5s, opacity 0.5s;
    opacity: 1;
  }
}

.review-fund-wrapper {
  & .funds-table-container {
    & .funds-table {
      & .tooltip-pool {
        color: black;
        width: 250px;
        background-color: white;
        border: 1px solid #5682e6;
        &::after {
          background-color: white;
          border: 1px solid #5682e6;
        }
      }

      thead th {
        &:not(:last-child) {
          padding-right: 0;
        }
      }

      tbody td {
        &:not(:last-child) {
          padding-right: 0;
        }
      }

      &__name {
        width: 15%;
      }

      &__grant-id {
        width: 10%;
      }
      &__grant-value {
        width: 15%;
      }
      &__submitted-date {
        width: 15%;
      }
      &__issued-date {
        width: 15%;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .review-fund-wrapper {
    h5 {
      font-size: 24px;
    }
    & .funds-table-container {
      & .funds-table {
        thead th {
          &:not(:last-child) {
            padding-right: 15px;
          }
        }

        tbody td {
          &:not(:last-child) {
            padding-right: 15px;
          }
        }

        &__name {
          min-width: 65px;
        }

        &__grant-id {
          min-width: 55px;
        }
        &__grant-value {
          min-width: 60px;
        }
        &__submitted-date {
          min-width: 65px;
        }
      }
    }
    & .review-recurring-grants {
      &__table-name {
        min-width: 60px;
      }

      &__table-end {
        min-width: 57px;
      }

      &__table-start {
        min-width: 62px;
      }
    }
  }
}
