.funds-table-container {
  @media (max-width: 576px) {
    overflow-x: scroll;
  }

  .funds-table {
    thead th {
      border-top: 0;
      padding: 10px 0;
      color: #acacac;
      font-weight: 700;
      font-size: 0.9rem;
      vertical-align: top;

      &:not(:last-child) {
        padding-right: 20px;
      }

      .tooltip-icon-wrapper {
        font-size: 16px;
      }

      .tooltip-mobile {
        @media (max-width: 576px) {
          &::after {
            left: 15% !important;
          }
          &::before {
            left: 15% !important;
          }
        }
      }
    }

    tbody td {
      padding: 15px 0;
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;

      &:not(:last-child) {
        padding-right: 20px;
      }

      a {
        text-decoration: underline !important;
      }

      .form-control.invalid {
        border: 1px solid #dc3545;
      }

      .error-message {
        font-size: 12px;
        color: #dc3545;
        font-weight: lighter;
      }
    }

    tbody .in-progress td {
      font-size: 12px;
      font-weight: lighter;
      padding: 4px 0;
      border-top: 1px solid #f2f2f2;

      &:not(:last-child) {
        padding-right: 20px;
      }
    }

    &__name {
      width: 25%;
    }

    &__allocation {
      min-width: 130px;
    }

    &__value-per-units {
      min-width: 120px;
    }

    &__transactions-history {
      min-width: 50px;
      max-width: 100px;
    }

    &__total-value {
      min-width: 110px;
    }

    &__submitted-date {
      max-width: 70px;
    }

    &__issued-date {
      max-width: 70px;
    }

    &__grant-amount {
      min-width: 90px;
    }

    .mw-70px {
      max-width: 70px;
    }
    &__grant-progress {
      max-width: 50px;
    }
  }
}

.grant-progress-modal {
  .comment-block {
    font-size: 0.9rem;
    background-color: #f8f9fa;
    border-top: 0;
    font-style: italic;
  }
}

@media (max-width: $breakpoint-sm) {
  .funds-table-container {
    .funds-table {
      thead th {
        padding: 0 0 12px 0;
        font-weight: 500;
        font-size: 12px;

        &:not(:last-child) {
          padding-right: 12px;
        }
      }

      tbody td {
        padding: 15px 0;
        font-size: 12px;

        &:not(:last-child) {
          padding-right: 12px;
        }
      }

      &__allocation {
        min-width: 95px;
      }

      &__value-per-units {
        min-width: 70px;
      }

      &__total-value {
        min-width: 70px;
      }

      &__date {
        width: 25%;
      }

      &__description {
        width: 50%;
      }

      &__name {
        min-width: 100px;
      }

      &__grant-id {
        min-width: 70px;
      }

      &__grant-value {
        min-width: 80px;
      }

      &__submitted-date {
        min-width: 85px;
        max-width: auto;
      }

      &__issued-date {
        max-width: auto;
        min-width: 70px;
      }

      &__grant-amount {
        min-width: 165px;
      }
    }
  }

  .table {
    margin-bottom: 8px;
  }

  .grant-progress-modal {
    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }

    & .table {
      th,
      td {
        font-size: 12px;
      }
    }

    .comment-block {
      font-size: 0.9rem;
      background-color: #f8f9fa;
      border-top: 0;
      font-style: italic;
    }
  }
}

.history-query-icon {
  stroke: #5682e6;

  &:hover {
    stroke: #0056b3;
  }
}
