.contact-us-wrapper {
  display: flex;

  .contact-us-info {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
  }
}

@media (max-width: $breakpoint-sm) {
  .contact-us {
    &__title {
      font-size: 24px;
    }
  }
  .contact-us-form {
    & textarea.form-control {
      height: 190px;
      resize: none;
    }
  }
}
