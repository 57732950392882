.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}
.control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
  color: #96989b;
  background-color: #ffffff;
  border: 1px solid #96989b;
  cursor: pointer;

  &:hover {
    background-color: $bg-gray;
  }
}

.page-ellipsis,
.control-icon {
  border: none;
  color: #818181;

  &:hover {
    background-color: #ffffff;
  }
}

.control:disabled {
  color: rgba(0, 0, 0, 0.3);
  border: none;
  pointer-events: none;
}
.disabled {
  pointer-events: none;
}
.active-page {
  color: #ffffff;
  background-color: $primary-btn;

  &:hover {
    background-color: $dark-primary-btn;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  column-gap: 20px;
}
.select-pagination {
  margin-left: 5px;
  color: white;
  background-color: transparent;
}
.select-pagination option {
  color: black;
}
