@mixin input-sm {
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  height: 52px;
  padding: 0 12px;
  width: 100%;
  border: 1px solid black;
}

@mixin select-sm {
  width: 100%;
  font-size: 20px;
  height: 52px;
  padding: 0 8px;
}
