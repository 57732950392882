.button {
  border: 0;
  background: transparent;
  margin: 0;

  &--dark-blue {
    color: white;
    background-color: $primary-btn;
    transition: all 0.5s;

    &:hover {
      background-color: $dark-primary-btn;
    }
  }

  &--red {
    color: white;
    background-color: $light-red;
    transition: all 0.5s;

    &:hover {
      background-color: $dark-red;
    }
  }

  &--gray {
    background-color: $light-gray;
    color: white;
    transition: all 0.5s;

    &:hover {
      background-color: $dark-gray;
    }
  }

  &--small {
    font-size: 0.85rem;
  }

  &--link {
    background: transparent;
    padding: 0;
    margin: 0;
    text-align: left;
    border: 0;
    color: #4582ec;
    cursor: pointer;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        color: #4582ec;
        text-decoration: none;
      }
    }
  }

  &--edit {
    height: 20px;
    width: 20px;
    background-image: url('~assets/images/edit_icon.png');

    &:hover {
      background-image: url('~assets/images/edit_icon_hovered.png');
    }
  }

  &--bin {
    height: 20px;
    width: 20px;
    background-image: url('~assets/images/bin_icon.png');

    &:hover {
      background-image: url('~assets/images/bin_icon_hovered.png');
    }
  }
}
