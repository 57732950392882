.burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;

  &__list {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    row-gap: 15px;
    font-size: 20px;

    & a {
      color: black;
    }

    & .active {
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    & .navbar__logout-btn {
      font-size: 20px;
      font-weight: normal;
      padding: 0;
    }
  }

  &.active {
    opacity: 1;
    transition: 0.5s;
    pointer-events: all;
  }

  &__content {
    position: absolute;
    padding: 16px;
    left: 20%;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.5s;
    transform: translateX(120%);
    background: white;
    z-index: 11;

    &.active {
      transform: translateX(0);
      display: block;
      transition: 0.5s;
    }

    & .button-logout {
      border: none;
      background-color: inherit;
      padding: 0;
    }

    & .button-close {
      position: absolute;
      right: 22px;
      top: 22px;
      border: none;
      background-color: inherit;
      display: block;
      padding: 0;

      & span {
        display: block;
        width: 20px;
        height: 20px;
        background: url('~assets/images/close-icon.svg');
        background-position: center center;
        background-size: 100% 100%;
      }
    }
  }
}
