@media (max-width: $breakpoint-sm) {
  .review-contributions {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h4 {
      font-size: 20px;
    }
  }
}
