.review-grants {
  &__header {
    background-color: $bg-gray;
  }

  .icon__question {
    height: 16px;
  }

  & .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  & .icon__reception {
    color: #5682e6;
    padding: 0;
    font-size: 24px;
    height: 24px;
    &:hover {
      color: #0056b3;
    }
  }

  & .tooltip {
    background-color: $primary-btn;
    &::after {
      background-color: $primary-btn !important;
    }
  }

  & .button-reset-filter {
    display: flex;
    align-items: flex-end;
    width: auto;
    position: absolute;
    bottom: -5px;
    right: 0;
    transform: translateX(100%);
    white-space: nowrap;
    color: $ocean-blue;
  }
}

@media (max-width: $breakpoint-sm) {
  .review-grants {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h4 {
      font-size: 20px;
    }

    &__header {
      background-color: inherit;
    }

    & .icon__reception {
      font-size: 16px;
    }
  }

  .tooltip {
    left: 15px !important;
    right: 15px !important;
    width: calc(100% - 30px) !important;
    font-size: 14px;
  }
}
