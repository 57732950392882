@media (max-width: $breakpoint-sm) {
  .recurring-grant-updated-modal {
    & .table-wrapper {
      overflow-x: scroll;
    }

    & .button {
      width: 100%;
    }

    & .table-value {
      min-width: 65px;
    }

    & .table-designation {
      min-width: 100px;
    }

    thead th {
      padding: 12px 0;
      font-weight: 500;
      font-size: 12px;

      &:not(:last-child) {
        padding-right: 12px;
      }
    }

    tbody td {
      padding: 10px 0;
      font-size: 12px;

      &:not(:last-child) {
        padding-right: 12px;
      }
    }
  }
}
